<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
            <!-- 사업장 -->
            <c-label-text title="LBLPLANT" :value="equipData.plantName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비유형 -->
            <c-label-text title="LBL0001720" :value="equipData.equipmentTypeName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비관리번호 -->
            <c-label-text title="LBL0001727" :value="equipData.equipmentNo"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비명 -->
            <c-label-text title="LBL0001725" :value="equipData.equipmentName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-tab
        type="tabcard"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        v-model="tab"
        align="left"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :popupParam="tab.param"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-grade',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        plantCd: '',
        psmFlag: '',
      }),
    },
    equipData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        equipmentTypeCd: null,  // 설비유형 코드
        equipmentTypeName: '',  // 설비유형명
        equipmentCd: '',  // 설비코드
        equipmentNo: '',  // 설비코드
        equipmentName: '',  // 설비명
        mainProcessCd: '',  // 주공정
        subProcessCd: '',  // 부공정
        managementDepts: '',  // 관리부서
        equipmentLocation: '',  // 설비위치
        installDate: '',  // 설치일자
        installVendor: '',  // 설치업체
        opertationDate: '',  // 가동일자
        checkPassNo: '',  // 검사합격번호
        checkPassDate: '',  // 합격일자
        discardFlag: 'N',
        discardDate: '',  // 폐기일자
        psmFlag: 'N',  // PSM 대상 여부
        psmTypeCd: null,
        amount: '',  // 수량
        lawEquipmentFlag: 'N',  // 법정설비 여부
        inspectionCycleCd: null,  // 법정점검 주기
        selfCycleCd: null,  // 자체점검 주기
        pmCycleCd: null,  // 자체점검 주기
        pmFlag: 'N',  // 예방정비 여부
        cmFlag: 'N',  // 고장정비 여부
        predictiveFlag: 'N',  // 예지정비 여부
        operFlag: 'N', // 운전정비 여부
        relatedLaws: '',  // 관련법규
        recentInspectionDate: '',  // 최근 점검일자
        outsourcingVendor: '',  // 유지보수업체
        hazardousFlag: 'N'
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'psmtab1',
      tabItems: [],
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.mdm.equipment.psm.list.url;
      this.getList();
    },
    getList() {
      if (this.popupParam.equipmentCd) {
        this.$http.url = this.listUrl;
        this.$http.param = {equipmentCd: this.popupParam.equipmentCd};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if(_result.data) {
            if (_result.data.length == 1) {
              if (_result.data[0].pid == 'NOT') {
                this.tabSettingPid(_result.data[0]);
              } else {
                this.tabSetting(_result.data);
              }
            } else {
              this.tabSetting(_result.data);
            }
          }
        },);
      }
    },
    tabSettingPid(_data) {
      if (this.popupParam.equipmentCd) {
        this.tabItems = [];
        let pageurl = '';
        let _param = {};
        if (_data.ptype == 'MPTC000001') {
          pageurl = import(`${'@/pages/psi/pfi/powerMachineDetail.vue'}`)
          _param = {popeditable: true, psiEquipmentCd: this.popupParam.equipmentCd};
        } else if (_data.ptype == 'MPTC000002') {
          pageurl = import(`${'@/pages/psi/pfi/deviceEquipmentDetail.vue'}`)
          _param = {popeditable: true, psiEquipmentCd: this.popupParam.equipmentCd};
        } else if (_data.ptype == 'MPTC000003') {
          pageurl = import(`${'@/pages/psi/pfi/safetyValveRuptureDiskDetail.vue'}`)
          _param = {popeditable: true, psiEquipmentCd: this.popupParam.equipmentCd};
        }
        this.tabItems.push({ name: 'psmtab1', icon: 'info', label: _data.pname, param: _param, component: () => pageurl });
        this.tab = 'psmtab1';
      }
    },
    tabSetting(_data) {
      if (this.popupParam.equipmentCd) {
        this.tabItems = [];
        let cnt = 1;
        this.$_.forEach(_data, item => {
          if (item.pid != 'NOT') {
            let pageurl = '';
            let _param = {};
            if (item.ptype == 'power') {
              pageurl = import(`${'@/pages/psi/pfi/powerMachineDetail.vue'}`)
              _param = {popeditable: true, psiPowerEquipmentId:item.pid};
            } else if (item.ptype == 'device') {
              pageurl = import(`${'@/pages/psi/pfi/deviceEquipmentDetail.vue'}`)
              _param = {popeditable: true, psiDeviceEquipmentId:item.pid};
            } else if (item.ptype == 'valve') {
              pageurl = import(`${'@/pages/psi/pfi/safetyValveRuptureDiskDetail.vue'}`)
              _param = {popeditable: true, psiValveRuptureId:item.pid};
            }
            this.tabItems.push({ name: 'psmtab' + cnt, icon: 'info', label:item.pname, param: _param, component: () => pageurl });
            cnt++;
          }
        });
        if (_data.length > 0) {
          this.tab = 'psmtab1';
        }
      }
    }
  }
};
</script>